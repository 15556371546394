@import 'workspace';
@import 'dashboard';
@import 'sizing';
@import 'login';
@import 'offer';
@import 'offerDocuments';
@import 'exportPreview';
@import 'aiResult';

.main-content {
	padding: 24px 48px;

	&.page-sizing {
		padding: 24px 0;
	}
}

.main-content-ai-result {
	display: flex;
}
